import { ref } from "vue";
import { useAsyncData } from "#build/imports";
import { useErrorMessageStored } from "~/store/errorMessageStored";
export const useGetData = () => {
  const data = ref([]);
  const loading = ref(false);
  const messageError = ref(null);
  const getData = async (url) => {
    let response = Object;
    let responseText = "";
    try {
      loading.value = true;
      response = await fetch(url);
      responseText = await response.text();
      const jsonBody = JSON.parse(responseText);

      // se lo status della risposta non è 2xx vuol dire che ho un errore.
      if (response.status > 300) {
        throw new Error(JSON.stringify(jsonBody));
      }

      if (
        jsonBody.success === false &&
        !jsonBody.message.includes("Non sono presenti")
      ) {
        throw new Error(jsonBody.message);
      }

      if ("error" in jsonBody) {
        throw new Error(JSON.stringify(jsonBody.error));
      }

      // gli errori gestiti in modo custom lato odoo, viene restituito dentro jsonBody.result.result
      // e result.success è false
      if (jsonBody.result) {
        if (jsonBody.result.success === false) {
          throw new Error(JSON.stringify(jsonBody.result.message));
        }
      }

      // assegno il valore della risposta a data
      data.value = jsonBody.result ? jsonBody.result : [];
    } catch (error) {
      const storeError = useErrorMessageStored();
      console.log(url, "errore", error, response);
      // se l'errore è riguardo al json parse
      if (error.toString().includes("JSON")) {
        // se la risposta è unn html e dentro è presente il testo Login, allora vuol dire
        // che manca la token oppure che la sessione è scaduta
        if (responseText.includes("o_login_auth")) {
          messageError.value = "Errore durante il login";
          storeError.messaggio = "Sessione scaduta. Riprova a fare il login";
          storeError.showError();
        } else {
          // altrimenti si tratta di un errore del formato json
          messageError.value = "Formato Json sbagliato";
          storeError.messaggio = "Formato Json sbagliato: " + error.toString();
          storeError.showError();
        }
      } else {
        messageError.value = error;
        storeError.messaggio = error.toString();
        storeError.showError();
      }
    } finally {
      // una volta finito tutto, metto false loading
      loading.value = false;
    }
  };
  return {
    getData,
    data,
    loading,
    messageError
  };
};
